import * as React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../../components';
import styles from './styles/index.module.scss';

export interface GatsbyLocation extends Location {
  state: { [key: string]: string };
}

export interface PageProps {
  location: GatsbyLocation;
  data: any;
  pageContext: any;
}

const Contact: React.FunctionComponent<PageProps> = (props) => {
  const { markdownRemark } = props.data;
  const { frontmatter } = markdownRemark;
  const [inputName, setInputName] = React.useState('');
  const [inputEmail, setInputEmail] = React.useState('');
  const [inputMessage, setInputMessage] = React.useState('');
  const [inputGotcha, SetInputGotcha] = React.useState('');
  const [submitMessage, SetSubmitMessage] = React.useState('');
  const [submitSuccess, SetSubmitSuccess] = React.useState<boolean | null>(
    null
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let data = {
      name: event.target[0].value,
      email: event.target[1].value,
      message: event.target[2].value,
      _gotcha: event.target[3].value,
    };

    //form spree code
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4) {
        if (this.status == 300) {
          //Antwort verarbeiten
          // TODO Text in Datei auslagern
          SetSubmitSuccess(true);
          SetSubmitMessage(
            'Thank you for contacting me. I will answer you soon.'
          );
        } else {
          SetSubmitSuccess(false);
          SetSubmitMessage(
            'Oops, this should not happen. You can contact me via this email: todo@test.com'
          );
        }
      }
    };
    //https://formcarry.com/s/6aGj-X7kbFG   - old url
    //https://formcarry.com/s/MwuRiWwfJZs  - Helens URL
    xhttp.open('POST', 'https://formcarry.com/s/6aGj-X7kbFG', true);
    xhttp.setRequestHeader('Content-type', 'application/json');
    xhttp.setRequestHeader('Accept', 'application/json');
    xhttp.send(JSON.stringify(data));
    return false;
  };

  const handleInputChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    switch (evt.target.name) {
      case 'name':
        setInputName(evt.target.value);
        break;
      case 'email':
        setInputEmail(evt.target.value);
        break;
      case 'message':
        setInputMessage(evt.target.value);
        break;
      case 'name':
        SetInputGotcha(evt.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <Layout locale={props.pageContext.locale} location={props.location}>
      <main>
        <section className={[styles.contact].join(' ')}>
          <div className={[styles.textImageComponent].join(' ')}>
            <div
              className={[styles.textImageComponentImage].join(' ')}
              style={{
                backgroundImage: `url('${frontmatter.image}')`,
              }}
            ></div>
            <article className={[styles.textImageComponentText].join(' ')}>
              <h5 className={[styles.question].join(' ')}>
                {frontmatter.question}
              </h5>
              <div className={[styles.suggestion].join(' ')}>
                <span>{frontmatter.suggestion}</span>
                <a href={'tel:' + frontmatter.phonenumber}>
                  {frontmatter.phonenumber}
                </a>
              </div>

              <form
                id="contact__form"
                className={[styles.contactForm, styles.formGroup].join(' ')}
                action=""
                onSubmit={handleSubmit}
              >
                <input
                  className={[styles.formInput].join(' ')}
                  name="name"
                  type="text"
                  placeholder={frontmatter.input_name}
                  aria-label="name"
                  value={inputName}
                  onChange={handleInputChange}
                />
                <input
                  className={[styles.formInput].join(' ')}
                  name="email"
                  type="text"
                  placeholder={frontmatter.input_email}
                  aria-label="email"
                  value={inputEmail}
                  onChange={handleInputChange}
                />
                <textarea
                  className={[styles.formInput].join(' ')}
                  name="message"
                  id=""
                  placeholder={frontmatter.input_message}
                  aria-label="message"
                  value={inputMessage}
                  onChange={handleInputChange}
                ></textarea>
                <input
                  type="hidden"
                  name="_gotcha"
                  value={inputGotcha}
                  onChange={handleInputChange}
                />
                <button
                  className={[
                    styles.btn,
                    styles.btnPrimary,
                    styles.contactSubmit,
                  ].join(' ')}
                  type="submit"
                >
                  {frontmatter.input_submit}
                </button>
              </form>

              <div
                className={[
                  submitSuccess === null ? styles.hidden : '',
                  submitSuccess === true ? styles.contactFormSuccess : '',
                  submitSuccess === false ? styles.contactFormError : '',
                ].join(' ')}
                id="contact__response"
              >
                {submitMessage}
              </div>
            </article>
          </div>
        </section>
        <script src="/js/contact.js"></script>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query($filepath: String!) {
    markdownRemark(fileAbsolutePath: { eq: $filepath }) {
      html
      frontmatter {
        question
        suggestion
        phonenumber
        nav_menu
        image
        title
        path
        input_name
        input_email
        input_message
        input_submit
      }
    }
  }
`;

export default Contact;
